@use "../../node_modules/normalize.css/normalize.css";
@use "./settings/colors" as *;
@use "./settings/breakpoints" as *;
@use "./settings/mixins" as *;
@use "./settings/utils";

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700&display=swap");

html,
body {
  font-family: "Cairo", sans-serif;
  font-size: 16px;
  line-height: 1.2em;
  scroll-behavior: smooth;
  position: relative;
  word-break: break-word;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $c-primary-light;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $c-primary;
  border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darken($c-primary, 10%);
}

.cf-socials-contact {
  width: 100%;

  a {
    display: inline-block;
    margin: 2px 3px;

    img {
      transition: all 300ms ease-in-out;
      margin: 5px;
    }

    &:hover img {
      filter: brightness(75%);
    }
  }
}
