@use "sass:string";
@use "sass:math";

@use "./breakpoints" as *;
@use "./colors" as *;
@use "./fonts" as *;
@use "./mixins" as *;

.container {
  max-width: $bp-xxl;
  margin: 0 auto;
  @include breakpoint($size: $bp-xxl) {
    max-width: 95%;
  }

  @include breakpoint($size: $bp-xl) {
    max-width: 90%;
  }

  @include breakpoint($bp-xs) {
    max-width: 95%;
  }
}

.container--md {
  @extend .container;
  max-width: $bp-xl;
  @include breakpoint($size: $bp-xxl) {
    max-width: 95%;
  }

  @include breakpoint($size: $bp-xl) {
    max-width: 90%;
  }

  @include breakpoint($bp-xs) {
    max-width: 95%;
  }
}

.text-center {
  text-align: center;
}

.text-strong {
  font-weight: bold;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.c-primary {
  color: $c-primary;
}

.c-primary-light {
  color: $c-primary-light;
}

.section-big {
  margin-top: 15rem;
  margin-bottom: 15rem;
}

.section {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.no-overflow {
  overflow: hidden;
}

.scrollbar {
  @include scrollbar;
}

.box-shadow {
  @include box-shadow;
}

.cf-cta {
  &.with-waterdrop {
    h5,
    h3 {
      position: relative;

      @include pseudoElementWaterdrop(
        $top: -35px,
        $left: 0,
        $width: 100%,
        $height: 150px,
        $opacity: 0.4,
        $blur: 25px
      );
    }
  }
}

.cf-waterdrop {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;

  @include breakpoint(1919px) {
    max-width: 100%;
  }

  > div {
    position: absolute;
    content: "";
    z-index: -1;
    background: linear-gradient(237deg, #00a1ff 0%, #00ffb7 100%);
    border-radius: 100%;
    opacity: 0.95;

    @include breakpoint($bp-sm) {
      width: 100% !important;
    }
  }
}

.cf-page-title {
  h1 {
    display: inline-block;
    margin: 0 auto;

    &.waterdrop-left {
      @include pseudoElementWaterdrop(
        $width: 453px,
        $height: 200px,
        $left: -6rem,
        $top: -1rem
      );
    }

    &.waterdrop-right {
      @include pseudoElementWaterdrop(
        $dir: "after",
        $width: 453px,
        $height: 200px,
        $right: -6rem,
        $top: -1rem
      );
    }

    @include breakpoint(992px) {
      &.waterdrop-left::after {
        left: 0;
      }

      &.waterdrop-right::after {
        right: 0;
      }
    }
  }
}

.cf-check-animated {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin: 0 20px;
}

.cf-bullet-points {
  min-width: 60px;
  .cf-bullet-point {
    $duration: 0.75s;
    --line-size: 4px;
    width: 25px;
    height: 25px;
    position: relative;
    display: inline-block;
    // margin: 100px;
    cursor: pointer;
    border: var(--line-size) solid rgba($c-primary, 0.5);
    border-radius: var(--line-size);

    span {
      position: absolute;
      display: block;
      background: $c-primary;
      transition: transform math.div($duration, 4) linear;
    }

    .top,
    .bottom {
      width: calc(100% + var(--line-size) * 2);
      height: var(--line-size);
      transform: scaleX(0);
    }
    .left,
    .right {
      width: var(--line-size);
      height: 100%;
      transform: scaleY(0);
    }

    .top {
      top: calc(var(--line-size) * -1);
      left: calc(var(--line-size) * -1);
      transition-delay: math.div($duration * 3, 4);
      transform-origin: top left;
      border-top-left-radius: var(--line-size);
      border-top-right-radius: var(--line-size);
    }

    .right {
      top: 0;
      right: calc(var(--line-size) * -1);
      transition-delay: math.div($duration, 2);
      transform-origin: top left;
    }

    .bottom {
      bottom: calc(var(--line-size) * -1);
      right: calc(var(--line-size) * -1);
      transition-delay: math.div($duration, 4);
      transform-origin: top right;
      border-bottom-left-radius: var(--line-size);
      border-bottom-right-radius: var(--line-size);
    }

    .left {
      top: 0;
      left: calc(var(--line-size) * -1);
      transform-origin: bottom left;
    }

    &:hover {
      .top {
        transition-delay: 0s;
      }
      .right {
        transition-delay: math.div($duration, 4);
      }
      .bottom {
        transition-delay: math.div($duration, 2);
      }
      .left {
        transition-delay: math.div($duration * 3, 4);
      }
      .top,
      .bottom {
        transform: scaleX(1);
      }
      .left,
      .right {
        transform: scaleY(1);
      }
    }

    transform: rotate(45deg);

    &:nth-child(2) {
      position: absolute;
      left: -21px;
      top: 82px;
    }

    &:nth-child(3) {
      position: absolute;
      left: 21px;
      top: 82px;
    }

    &:nth-child(4) {
      position: absolute;
      left: 0px;
      top: 103px;
    }
  }
}

.text-xs {
  @include useFontSize("text-xs");
}
.text-sm {
  @include useFontSize("text-sm");
}
.text-base {
  @include useFontSize("text-base");
}
.text-lg {
  @include useFontSize("text-lg");
}
.text-xl {
  @include useFontSize("text-xl");
}
.text-2xl {
  @include useFontSize("text-2xl");
}
.text-3xl {
  @include useFontSize("text-3xl");
}
.text-4xl {
  @include useFontSize("text-4xl");
}
.text-5xl {
  @include useFontSize("text-5xl");
}
.text-6xl {
  @include useFontSize("text-6xl");
}
.text-7xl {
  @include useFontSize("text-7xl");
}
.text-8xl {
  @include useFontSize("text-8xl");
}
.text-9xl {
  @include useFontSize("text-9xl");
}

h1 {
  @include useFontSize("text-7xl");
}

h2 {
  @include useFontSize("text-6xl");
}

h3 {
  @include useFontSize("text-5xl");
}

h4 {
  @include useFontSize("text-4xl");
}

h5 {
  @include useFontSize("text-3xl");
}

h6 {
  @include useFontSize("text-2xl");
}

p {
  @include useFontSize("text-base");
}

@include breakpoint($size: $bp-lg) {
  h1 {
    @include useFontSize("text-5xl");
  }

  h2 {
    @include useFontSize("text-5xl");
  }

  h3 {
    @include useFontSize("text-5xl");
  }

  .text-5xl {
    @include useFontSize("text-5xl");
  }
  .text-6xl {
    @include useFontSize("text-5xl");
  }
  .text-7xl {
    @include useFontSize("text-5xl");
  }
  .text-8xl {
    @include useFontSize("text-5xl");
  }
  .text-9xl {
    @include useFontSize("text-5xl");
  }
}
